var reducers = {
  getCommunityList: function getCommunityList(state, action) {
    state.communityList = action.payload;
    return state;
  },
  getPost: function getPost(state, action) {
    state.post = action.payload;
    return state;
  },
  getPostComments: function getPostComments(state, action) {
    state.comments = action.payload;
    return state;
  },
  getNotices: function getNotices(state, action) {
    state.notices = action.payload;
    return state;
  },
  getNoticesFixed: function getNoticesFixed(state, action) {
    state.noticesFixed = action.payload;
    return state;
  },
  getEvent: function getEvent(state, action) {
    state.event = action.payload;
    return state;
  },
  getNotice: function getNotice(state, action) {
    state.notice = action.payload;
    return state;
  },
  getEvents: function getEvents(state, action) {
    state.events = action.payload;
    return state;
  },
  setBranchNews: function setBranchNews(state, action) {
    state.branch_news = action.payload;
    return state;
  },
  setCommunitySelectUser: function setCommunitySelectUser(state, action) {
    state.community_select_user = action.payload;
    return state;
  },
  setUserPostList: function setUserPostList(state, action) {
    state.userPostList = action.payload;
    return state;
  },
  setBranchEvent: function setBranchEvent(state, action) {
    state.branch_event = action.payload;
    return state;
  },
  setBranchPost: function setBranchPost(state, action) {
    state.branch_post = action.payload;
    return state;
  }
};
export default reducers;