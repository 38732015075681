var reducers = {
  setSelectedBranches: function setSelectedBranches(state, action) {
    state.selected_branches.push(action.payload);
    return state;
  },
  removeSelectedBranches: function removeSelectedBranches(state, action) {
    var index = state.selected_branches.indexOf(action.payload);
    state.selected_branches.splice(index, 1);
    return state;
  },
  setSelectedService: function setSelectedService(state, action) {
    state.selected_service.push(action.payload);
    return state;
  },
  removeSelectedService: function removeSelectedService(state, action) {
    var index = state.selected_service.indexOf(action.payload);
    state.selected_service.splice(index, 1);
    return state;
  }
};
export default reducers;