var reducers = {
  getPrivateService: function getPrivateService(state, action) {
    state.privateSpace = action.payload;
    return state;
  },
  setMembershipService: function setMembershipService(state, action) {
    state.membershipService = action.payload;
    return state;
  }
};
export default reducers;