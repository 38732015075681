var reducers = {
  setLogout: function setLogout(state) {
    state.isLogin = false;
    state.socialAccounts = null;
    state.user = null;
    state.access = '';
    state.join_info = null;
    state.refresh = null;
    return state;
  },
  setJoinInfo: function setJoinInfo(state, action) {
    state.join_info = action.payload;
    return state;
  },
  setLogin: function setLogin(state, action) {
    state.isLogin = action.payload;
    return state;
  },
  setRefreshToken: function setRefreshToken(state, action) {
    state.refresh = action.payload;
    return state;
  },
  setSNSInfo: function setSNSInfo(state, action) {
    state.join_info = {
      name: '',
      phone_number: '',
      socialaccounts: [action.payload]
    };
    return state;
  },
  setSocialToken: function setSocialToken(state, action) {
    state.social = action.payload;
    return state;
  },
  setSocialEmail: function setSocialEmail(state, action) {
    state.socialMail = action.payload;
    return state;
  },
  setAccessToken: function setAccessToken(state, action) {
    state.access = action.payload;
    return state;
  },
  setUserInfo: function setUserInfo(state, action) {
    state.user = action.payload;
    return state;
  },
  setSocialAccounts: function setSocialAccounts(state, action) {
    state.socialAccounts = action.payload;
    return state;
  },
  setSocialAccountData: function setSocialAccountData(state, action) {
    state.socialAccountData = action.payload;
    return state;
  }
};
export default reducers;