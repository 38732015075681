var reducers = {
  setAllOrders: function setAllOrders(state, action) {
    state.orders = action.payload;
    return state;
  },
  setOrder: function setOrder(state, action) {
    state.order = action.payload;
    return state;
  }
};
export default reducers;