import _defineProperty from "/home/ubuntu/Local-Stitch-Front/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useSelector as useReduxSelector } from 'react-redux';
import space from './space';
import site from './site';
import accounts from './accounts';
import application from './application';
import service from './service';
import membership from './membership';
import payments from './payments';
import community from './community';
import orders from './orders';
var rootReducer = combineReducers({
  space: space.reducer,
  site: site.reducer,
  accounts: accounts.reducer,
  application: application.reducer,
  service: service.reducer,
  membership: membership.reducer,
  payments: payments.reducer,
  community: community.reducer,
  orders: orders.reducer
});
var initialRootState;

var reducer = function reducer(state, action) {
  if (action.type === HYDRATE) {
    if (state === initialRootState) {
      return _objectSpread(_objectSpread({}, state), action.payload);
    }

    return state;
  }

  return rootReducer(state, action);
};

export var useSelector = useReduxSelector;

var initStore = function initStore() {
  var store = configureStore({
    reducer: reducer,
    devTools: false
  });
  initialRootState = store.getState();
  return store;
};

export var wrapper = createWrapper(initStore);