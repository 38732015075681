var reducers = {
  getFAQs: function getFAQs(state, action) {
    state.faqs = action.payload;
    return state;
  },
  getTerm: function getTerm(state, action) {
    state.terms = action.payload;
    return state;
  },
  getRentPlace: function getRentPlace(state, action) {
    state.rent_place = action.payload;
    return state;
  },
  getCompanyHistory: function getCompanyHistory(state, action) {
    state.company_history = action.payload;
    return state;
  },
  getMediaNews: function getMediaNews(state, action) {
    state.media_news = action.payload;
    return state;
  },
  getIntroduction: function getIntroduction(state, action) {
    state.introduction = action.payload;
    return state;
  }
};
export default reducers;