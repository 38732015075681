var reducers = {
  getBranches: function getBranches(state, action) {
    state.branches = action.payload;
    return state;
  },
  getFavoriteBranches: function getFavoriteBranches(state, action) {
    state.favorite_branches = action.payload;
    return state;
  },
  getBranch: function getBranch(state, action) {
    state.branch = action.payload;
    return state;
  },
  getRegions: function getRegions(state, action) {
    state.regions = action.payload;
    return state;
  },
  getOffices: function getOffices(state, action) {
    state.offices = action.payload;
    return state;
  },
  getMetaOffice: function getMetaOffice(state, action) {
    state.meta_offices = action.payload;
    return state;
  },
  getMeetingRoom: function getMeetingRoom(state, action) {
    state.meeting_room = action.payload;
    return state;
  },
  getMeetingRooms: function getMeetingRooms(state, action) {
    state.meeting_rooms = action.payload;
    return state;
  },
  getRetails: function getRetails(state, action) {
    state.retails = action.payload;
    return state;
  },
  getRetail: function getRetail(state, action) {
    state.retail = action.payload;
    return state;
  },
  getRemainingHours: function getRemainingHours(state, action) {
    state.remaining_hours = action.payload;
    return state;
  },
  getReservations: function getReservations(state, action) {
    state.reservations = action.payload;
    return state;
  }
};
export default reducers;