var reducers = {
  setVirtualAccount: function setVirtualAccount(state, action) {
    state.virtual_account = action.payload;
    return state;
  },
  setCards: function setCards(state, action) {
    state.cards = action.payload;
    return state;
  },
  selectCard: function selectCard(state, action) {
    state.select_card = action.payload;
    return state;
  },
  selectVirtualAccount: function selectVirtualAccount(state, action) {
    state.select_virtual_account = action.payload;
    return state;
  },
  setTaxProfile: function setTaxProfile(state, action) {
    state.tax_profile = action.payload;
    return state;
  },
  setBanks: function setBanks(state, action) {
    state.banks = action.payload;
    return state;
  }
};
export default reducers;