var reducers = {
  setMembershipInfo: function setMembershipInfo(state, action) {
    state.membership = action.payload;
    return state;
  },
  setMember: function setMember(state, action) {
    state.member = action.payload;
    return state;
  },
  setMembers: function setMembers(state, action) {
    state.members = action.payload;
    return state;
  },
  setMembershipPrivateSpace: function setMembershipPrivateSpace(state, action) {
    state.membershipSpace = action.payload;
    return state;
  },
  setExceptMember: function setExceptMember(state, action) {
    state.exceptMembers = action.payload;
    return state;
  },
  setNumper: function setNumper(state, action) {
    state.numperMember = action.payload;
    return state;
  },
  setLogoutMember: function setLogoutMember(state) {
    state.membership = null;
    state.member = null;
    state.members = [];
    state.membershipSpace = [];
    state.exceptMembers = [];
    state.numperMember = 0;
    state.invite_code = null;
    return state;
  },
  setInviteCode: function setInviteCode(state, action) {
    state.invite_code = action.payload;
    return state;
  }
};
export default reducers;